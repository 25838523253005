<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradientqq" x1="0.651" x2="0.122" y2="1">
     <stop offset="0" stop-color="#ffeb00"/>
     <stop offset="1" stop-color="#ffba00"/>
    </linearGradient>
   </defs>
   <g id="Snapchat" transform="translate(-530 -208)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(530 208)" width="60"/>
    <path d="M31.839,24.6c-.325.758-1.7,1.314-4.2,1.7a11.691,11.691,0,0,0-.406,1.493.722.722,0,0,1-.756.553h-.019c-.586,0-1.2-.269-2.421-.269a4.933,4.933,0,0,0-3.506,1.283,7.02,7.02,0,0,1-4.614,1.708,7.158,7.158,0,0,1-4.542-1.708,4.927,4.927,0,0,0-3.505-1.283,23.541,23.541,0,0,0-2.421.294.74.74,0,0,1-.774-.563,11.545,11.545,0,0,0-.407-1.5c-1.289-.2-4.2-.707-4.269-2a.662.662,0,0,1,.553-.69c4.337-.714,6.29-5.167,6.371-5.356l.015-.032a1.417,1.417,0,0,0,.154-1.169c-.315-.741-1.675-1.007-2.247-1.234-1.478-.584-1.684-1.255-1.6-1.714a1.6,1.6,0,0,1,2.057-.963,3.567,3.567,0,0,0,1.468.392A1.272,1.272,0,0,0,7.4,13.4c-.127-2.24-.443-5.44.354-7.228a8.491,8.491,0,0,1,7.883-5.086l.63-.006a8.433,8.433,0,0,1,7.9,5.088c.8,1.786.483,4.973.355,7.23a1.221,1.221,0,0,0,.536.133,3.723,3.723,0,0,0,1.375-.39,1.687,1.687,0,0,1,1.276,0h0a1.3,1.3,0,0,1,.972,1.114c.011.607-.531,1.132-1.613,1.559-.132.052-.293.1-.463.157-.611.194-1.533.486-1.784,1.076a1.416,1.416,0,0,0,.153,1.168c.005.01.01.021.015.032.081.189,2.033,4.641,6.372,5.356A.68.68,0,0,1,31.839,24.6Z" data-name="Icon awesome-snapchat-ghost" id="Icon_awesome-snapchat-ghost" style="fill: url(#linear-gradientqq);" transform="translate(544 221.922)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>